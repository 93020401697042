/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

.custom-status-chip {
    padding: 5px 10px !important;
    font-size: var(--fs-13) !important;
    line-height: 13px !important;
}

.bg-chip-red {
    background-color: var(--pink) !important;
    color: var(--red) !important;
}

.bg-chip-green {
    background-color: var(--green-one) !important;
    color: var(--green) !important;
}

.bg-chip-ornage {
    background-color: var(--orange-one) !important;
    color: var(--orange) !important;
}