/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

.campaigns-type-content .campaigns-type-header h5 {
    font-size: var(--fs-18);
    color: var(--black-one);
    line-height: 22px;
}

.campaigns-type-content .campaigns-type-header p {
    color: var(--muted);
    line-height: 20px;
    font-size: var(--fs-15);
}


.campaigns-type-content .switch-tab .icon {
    height: 38px;
    width: 38px;
    background-color: var(--purple);
}

.campaigns-type-content .switch-tab p {
    font-size: var(--fs-15);
    color: var(--black-one);
}