/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

aside.sidebar {
    width: 260px;
    min-width: 260px;
    max-width: 260px;
    box-shadow: 0 .125rem .5rem 0 var(--muted-twelve);
}

aside.sidebar .site-logo {
    height: 64px;
    padding: 0 16px 0 22px;
}

aside.sidebar .effect-logo::after {
    content: '';
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    width: 100%;
    pointer-events: none;
    z-index: 2;
    background: linear-gradient(var(--white)41%, var(--white-two) 95%, rgba(255, 255, 255, 0));
    height: 48px;
}

aside.sidebar .sidebar-item {
    margin-top: 6px;
    font-size: var(--fs-15)
}

aside.sidebar .sidebar-item a {
    color: var(--black-one);
    border-radius: 6px;
    margin: 0 12px;
    padding: 8px 12px;
}

aside.sidebar .sidebar-item a:hover {
    background-color: var(--muted-eleven);
}

aside.sidebar .sidebar-item a.active {
    background: linear-gradient(270deg, var(--purple-three) 0%, var(--purple) 100%);
    box-shadow: 0px 2px 6px 0px var(--purple-four);
    color: var(--white);
}

aside.sidebar .sidebar-item a.active svg {
    color: var(--white);
}

.purple-color {
  background: linear-gradient(270deg, var(--purple-three) 0%, var(--purple) 100%);
  box-shadow: 0px 2px 6px 0px var(--purple-four);
  color: var(--white);
}

.rotate-icon {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.rotate-icon.rotate {
  transform: rotate(90deg);
}

  .sidebar-item.open .dropdown-content {
    display: block; /* Show dropdown when open */
  }
  
  .dropdown-content {
    display: none; /* Initially hidden */
    margin-top: 8px;
    padding-left: 16px; /* Indent dropdown items */
  }
  
  .sidebar-subitem a {
    font-size: 14px; /* Slightly smaller font for subitems */
    color: var(--black-one);
  }
  
  .sidebar-subitem a:hover {
    color: var(--purple);
  }
  