/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

.profile-card {
    border-radius: 6px;
    box-shadow: 0 .1875rem .75rem 0 var( --muted-nineteen);
}

.profile-card .poster-img {
    height: 250px;
}

.profile-card .profile-info{
    margin-top: -32px;
    margin-bottom: 20px;
}