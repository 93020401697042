/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

.add-message-content h5 {
    font-size: var(--fs-18);
    color: var(--black-one);
    line-height: 22px;
}

.add-message-content p {
    color: var(--muted);
    line-height: 20px;
    font-size: var(--fs-15);
}

.add-message-content .addmsg-textarea{
    min-height: 150px;
}