/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-size: var(--fs-15) !important;
    font-family: "Public Sans", " -apple-system, BlinkMacSystemFont", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    font-optical-sizing: auto;
    font-weight: var(--fw-normal);
    font-style: normal;
    background-color: var(--white-one) !important;
    overflow-x: hidden;
    height: 100%;

}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0 !important;
}


.cursor-pointer {
    cursor: pointer !important;
}

img:focus-visible {
    outline: none;
}

.z-6 {
    z-index: 6;
}

.z-7 {
    z-index: 7;
}

.z-8 {
    z-index: 8;
}

.z-9 {
    z-index: 9;
}

.z-10 {
    z-index: 10;
}

.z-11 {
    z-index: 11;
}

.z-12 {
    z-index: 12;
}

.z-13 {
    z-index: 13;
}

.z-14 {
    z-index: 14;
}

.z-15 {
    z-index: 15;
}

.z-9999 {
    z-index: 9999;
}

.bg-toast-success {
    background-color: var(--green-one) !important;
    border-color: var(--green) !important;
    color: var(--green) !important;
}

.bg-toast-success svg {
    color: var(--green) !important;
}

.bg-toast-danger {
    background-color: var(--pink) !important;
    border-color: var(--red) !important;
    color: var(--red) !important;
}

.bg-toast-danger svg {
    color: var(--red) !important;
}

.bg-toast-warning {
    background-color: var(--orange-one) !important;
    border-color: var(--orange) !important;
    color: var(--orange) !important;
}

.bg-toast-warning svg {
    color: var(--orange) !important;
}

.common-shadow {
    background-clip: padding-box !important;
    box-shadow: 0 .1875rem .75rem 0 var(--muted-nineteen) !important;
}

.common-one-shadow {
    box-shadow: 0 .125rem .375rem 0 var(--purple-four) !important;
}

.base-search {
    min-width: 250px;
    max-width: 250px;
}

.divider-text {
    font-size: var(--fs-15);
    color: var(--black-one);
}

.divider-text::before,
.divider-text::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 100vw;
    border-top: 1px solid var(--muted-sixteen);
}

.divider-text::before {
    right: 100%;
}

.divider-text::after {
    left: 100%;
}

.textarea-height {
    min-height: 150px !important;
    max-height: 150px !important;
}

.h-divider {
    height: 1px;
    width: 100%;
    background-color: var(--muted-twentyone) !important;
}