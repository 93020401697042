/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */
.common-popup-backdrop {
    background-color: var(--white-five) !important;
    opacity: 0.4 !important;
}

.common-popup-dialog {
    border-radius: 8px !important;
}

.common-popup-dialog .common-popup-content {
    padding: 48px;
    border-radius: 8px !important;
    position: relative !important;
    border: none !important;
    box-shadow: 0 .25rem 1.125rem 0 var(--muted-eighteen);
}

.common-popup-dialog .common-popup-content .common-popup-body {
    padding: 24px;
    position: static;
}

.common-popup-dialog .common-popup-content .common-popup-body .common-popup-close-btn {
    height: 30px;
    width: 30px;
    transition: all .23s ease .1s;
    box-shadow: 0 .0625rem .375rem 0 var(--muted-twentyeight);
    top: -10px;
    right: -7px !important;
}

.common-popup-dialog .common-popup-content .common-popup-body .common-popup-close-btn:hover {
    transition: all .23s ease .1s;
    top: -7px;
    right: -2px !important;
}


.common-popup-dialog .common-popup-content .common-popup-body .common-popup-heading h4 {
    font-size: var(--fs-24);
    line-height: 38px;
    color: var(--blacl-one);
}

.common-popup-dialog .common-popup-content .common-popup-body .common-popup-heading p {
    color: var(--muted);
    line-height: 20px;
    font-size: var(--fs-15);
}

.common-popup-dialog .common-popup-content .common-popup-body .common-popup-btn button {
    min-width: 92px;
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1399px) {
    .common-popup-dialog .common-popup-content {
        padding: 32px;
    }
}

@media screen and (max-width:1199px) {}

@media screen and (max-width:991px) {}

@media screen and (max-width:767px) {}

/* <------- Media Query End -------> */