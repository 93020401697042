/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */
.pg-content {
    height: 100%;
    width: calc(100% + -260px);
    margin-left: 260px;
}


.pg-content::before {
    content: "";
    width: 100%;
    height: 4.5rem;
    position: fixed;
    top: 0px;
    z-index: 10;
    backdrop-filter: saturate(200%) blur(10px);
    background: linear-gradient(180deg, var(--muted-thirdteen) 44%, var(--muted-fourteen) 73%, var(--muted-fifthteen));
    mask: linear-gradient(var(--white-one), var(--white-one) 18%, transparent 100%);
}

.pg-content .pg-container {
    max-width: calc(1440px - 24px* 2);
}