/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

.common-action-btn .drop-btn {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    box-shadow: none;
    transition: all .2s ease-in-out;
}

.common-action-btn .drop-btn:hover,
.common-action-btn .drop-btn:focus,
.common-action-btn .drop-btn:active {
    background-color: var(--muted-twentyfour);
    transition: all .2s ease-in-out;
}

.common-action-btn .action-drop-menu {
    box-shadow: 0 .25rem 1.125rem 0 var(--muted-eighteen) !important;
}

.common-action-btn .action-drop-menu .action-drop-item {
    font-size: var(--fs-15) !important;
    color: var(--black-one) !important;
    padding: 9px 16px;
    margin-top: 2px;

}

.common-action-btn .action-drop-menu .action-drop-item:first-child {
    margin-top: 0px;
}

.common-action-btn .action-drop-menu .action-drop-item:hover {
    background-color: var(--muted-seventeen);
}

.common-action-btn .drop-btn::after {
    display: none;
}