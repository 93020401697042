
 /* .quill .ql-container {
    height: 120px;
} */

  .custom-editor .ql-container {
    height: inherit !important;
    /* max-height: 100%; */
    overflow-y: auto;
  }
