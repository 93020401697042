/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.

   Note: Not Change Any Css Postion in this File.
=================================================== */

.common-btn {
    padding: 7.5px 20px !important;
    font-size: var(--fs-15) !important;
}

/* Primary Button Style Start */
.btn-primary {
    background-color: var(--purple) !important;
    color: var(--white) !important;
}

.btn-primary:hover {
    background-color: var(--purple-five) !important;
}

.primary-shadow {
    box-shadow: 0 .125rem .375rem 0 var(--purple-four);
}

.btn-outline-primary {
    border: 1px solid var(--purple) !important;
    color: var(--purple) !important;
}


/* Muted Button Style Start */
.btn-muted {
    background-color: var(--muted-seven) !important;
    color: var(--muted-one) !important;
}

.btn-muted:hover {
    background-color: var(--muted-twentynine) !important;
}

/* Danger-outline Button Style Start */
.btn-outline-danger {
    border: 1px solid var(--red) !important;
    color: var(--red) !important;
}