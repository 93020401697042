.custom-range::-webkit-slider-thumb {
    background-color: var(--white) !important;
    border: 2px solid var(--black-one) !important;
    margin-top: -6px !important;
    box-shadow: none !important;
}

.custom-range::-webkit-slider-runnable-track {
    background-color: var(--muted-six);
    height: 4px !important;
    margin-top: 3px;
}