/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

.import-number-content .import-number-header h5 {
    font-size: var(--fs-18);
    color: var(--black-one);
    line-height: 22px;
}

.import-number-content .import-number-header p {
    color: var(--muted);
    line-height: 20px;
    font-size: var(--fs-15);
}

.import-number-content .reiview-duplicates-content h5 {
    font-size: var(--fs-18);
    color: var(--black-one);
    line-height: 22px;
}

.import-number-content .reiview-duplicates-content span {
    height: 12px;
    width: 12px;
    margin-top: 6px;
}

.import-number-content .reiview-duplicates-content p {
    color: var(--muted);
}

.import-number-content .allow-duplicates-contnt {
    max-width: 600px;
    padding: 8px 12px;
    background-color: var(--purple-two);
}

.import-number-content .allow-duplicates-contnt p {
    color: var(--black-one);
    line-height: 20px;
}

.bg-dot-purple {
    background-color: var(--purple) !important;
}

.bg-dot-red {
    background-color: var(--red) !important;
}

.v-divider {
    height: 33px;
    width: 1px;
    background-color: var(--muted-twentyone) !important;
}

.manual-import-content small {
    height: 7px;
    width: 7px;
    background-color: var(--black-one);
}

.import-from-file {
    border: 2px dashed var(--muted-six);
    border-radius: 6px;
    padding: 70px 24px 48px 24px;
}

.import-from-file h4 {
    color: var(--black-one);
    font-size: var(--fs-24);
    line-height: 27px;
}

.import-from-file a {
    color: var(--purple) !important;
    font-size: var(--fs-15) !important;
}

.import-from-file .icon {
    height: 40px;
    width: 40px;
    background-color: var(--muted-eight);
}