/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */
.custom-switch {
    line-height: 17px;
}

.custom-switch input {
    margin-top: 0px;
}

.custom-switch input:checked {
    background-color: var(--purple) !important;
    border-color: var(--purple) !important;
}


.custom-switch input:focus {
    box-shadow: none !important;
}

.sm-switch input {
    height: 1.1em !important;
    width: 2.1em !important;
}

.md-switch input {
    height: 1.2em !important;
    width: 2.2em !important;
}

.lg-switch input {
    height: 1.3em !important;
    width: 2.3em !important;
}

.xl-switch input {
    height: 1.4em !important;
    width: 2.4em !important;
}

.xxl-switch input {
    height: 1.5em !important;
    width: 2.6em !important;
}