/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

.common-form-label {
    font-size: var(--fs-13);
    line-height: 18px;
    color: var(--black-one);
    letter-spacing: inherit;
    text-transform: inherit;
}