/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

.import-file-content .import-file-header h5 {
   font-size: var(--fs-18);
   color: var(--black-one);
   line-height: 22px;
}

.import-file-content .import-file-header p {
   color: var(--muted);
   line-height: 20px;
   font-size: var(--fs-15);
}

.import-file-content .upload-contnt {
   border: 2px dashed var(--muted-six);
   padding: 20px;
   min-height: 200px;
}

.import-file-content .upload-contnt .upload-icon {
   height: 40px;
   width: 40px;
   background-color: var(--muted-eight);
}

.import-file-content .upload-contnt h5 {
   font-size: var(--fs-18);
   color: var(--black-one);
   line-height: 22px;
}

.import-file-content .import-file-preview {
   min-height: 300px;
   max-width: 300px;
   border-color: var(--muted-ten) !important;
}

.import-file-content .import-file-preview .swiper-pagination {
   color: var(--black-one) !important;
   font-weight: var(--font-semibold);
   max-width: 60px !important;
   margin: 16px auto 0 auto;
   position: static;
   text-align: center;

}

.import-file-content .slider-icon {
   height: 32px;
   width: 32px;
   background-color: var(--muted-ten);
   cursor: pointer;
}

.import-file-content .slider-icon.swiper-button-disabled{
   cursor: default !important;
}

.import-file-content .slider-icon:hover {
   background-color: var(--purple-two);
}

.import-file-content .slider-icon:hover svg {
   color: var(--purple);

}