/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */
.login-content {
    min-height: 100vh;
}

.login-content .login-sub-content {
    max-width: 460px;
}

.login-content .login-sub-content::before {
    width: 238px;
    height: 233px;
    content: " ";
    z-index: -1;
    position: absolute;
    top: -35px;
    left: -45px;
    background-image: url('../../assets/images/login/shapes/shape-top.svg');
}

.login-content .login-sub-content::after {
    width: 180px;
    height: 180px;
    content: " ";
    position: absolute;
    z-index: -1;
    bottom: -30px;
    right: -56px;
    background-image: url('../../assets/images/login/shapes/shape-bottom.svg');
}

.login-content .login-sub-content .login-card .welcome-content h4 {
    font-size: var(--fs-24);
    color: var(--black-one);
    line-height: 38px;
}

.login-content .login-sub-content .login-card .welcome-content p {
    font-size: var(--fs-15);
    line-height: 21px;
    color: var(--muted);
}

.login-content .login-sub-content .login-card .forget-link {
    margin-bottom: 32px;
}

.login-content .login-sub-content .login-card .forget-link a {
    font-size: var(--fs-15);
    color: var(--purple);
}

.login-content .login-sub-content .login-card .register-link a {
    font-size: var(--fs-15);
    color: var(--purple);
}

.login-content .login-sub-content .login-card .form-icon {
    height: 34px;
    width: 34px;
}

.login-content .login-sub-content .login-card .form-icon.facebook-icon:hover {
    background-color: var(--muted-twentyseven);
}

.login-content .login-sub-content .login-card .form-icon.twiter-icon:hover {
    background-color: var(--black-two);
}

.login-content .login-sub-content .login-card .form-icon.linkedin-icon:hover {
    background-color: var(--blue);
}

.login-content .login-sub-content .login-card .form-icon.google-icon:hover {
    background-color: var(--red-two);
}