/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

.send-invitaion-left-content h5 {
    font-size: var(--fs-18) !important;
    line-height: 22px !important;
    color: var(--black-one) !important;
}

.send-invitaion-left-content p {
    color: var(--black-one);
    line-height: 20px;
}

.send-invitaion-left-content .custom-color-picker {
    padding-right: 50px !important;

}

.send-invitaion-right-content h5 {
    font-size: var(--fs-18) !important;
    line-height: 22px !important;
    color: var(--black-one) !important;
}


.send-invitaion-right-content .pdf-preview-content .konvajs-content {
    position: static !important;
    height: 100% !important;
    width: 100% !important;

}

.send-invitaion-right-content .pdf-preview-content canvas {
    position: static !important;
    height: 100% !important;
    width: 100% !important;
    object-fit: cover !important;
    border: 1px solid var(--muted-six) !important;
}

.send-invitaion-right-content .img-view-content {
    /* max-height: 450px; */
    /* width: 100px !important; */
    /* overflow-y: auto; */
    height: 450px;
    /* width: 220px; */
}

.send-invitaion-right-content .img-view-content .img-view {
    border: 1px solid var(--muted-six) !important;
}